var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"ads-details-card p-1",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_vm._t("actions",null,null,_vm.adsDto)]},proxy:true}],null,true)},[_c('b-card-header',{staticClass:"ads-details-card__header position-relative",attrs:{"header-class":"p-0 mb-2"}},[_c('div',{staticClass:"ads-details-card__title-container d-flex"},[_c('unicon',{attrs:{"name":"tv-retro","width":"20","height":"20"}}),_c('h4',{staticClass:"ads-details-card__title ml-1 mb-0"},[_vm._v("تفاصيل الإعلان")])],1),_c('div',{staticClass:"ads-details-card__ads-status-container d-flex align-items-center"},[_c('div',{staticClass:"ads-details-card__ads-status-title d-flex"},[_vm._v("حالة الإعلان:")]),_c('StatusBadge',{staticClass:"ads-details-card__ads-status ml-1",attrs:{"statusList":_vm.adsStatus,"selectedStatusNumber":_vm.adsDto.dashResponse}})],1)]),_c('div',{staticClass:"card-title-seperator"}),_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ek-input-select',{attrs:{"disabled":_vm.isOrder,"options":_vm.activitiesList,"rules":[
                {
                  type: 'required',
                  message: 'اسم الفعالية مطلوب',
                } ],"label":"اسم الفعالية","name":"senderId"},on:{"input":_vm.updateSelctedActivity},model:{value:(_vm.adsDto.senderId),callback:function ($$v) {_vm.$set(_vm.adsDto, "senderId", $$v)},expression:"adsDto.senderId"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ek-input-text',{attrs:{"disabled":"","readonly":"","label":"النشاط التجاري","name":"CommercialActivity","value":_vm.adsDto.comarcialActivityName}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ek-input-text',{attrs:{"readonly":"","disabled":"","label":"المدينة","name":"city","value":_vm.adsDto.cityName}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ek-date-picker',{attrs:{"disabled":_vm.isOrder,"range":"","rules":[
                {
                  type: 'required',
                  message: 'تاريخ الإعلان مطلوب',
                },
                {
                  type: 'min:15',
                  message: 'يرجى إدخال تاريخ بداية و تاريخ نهاية',
                } ],"label":"تاريخ الإعلان","name":"adsDate"},on:{"input":function($event){return _vm.updateAdsDate($event)}},model:{value:(_vm.adsDto.adsDatesList),callback:function ($$v) {_vm.$set(_vm.adsDto, "adsDatesList", $$v)},expression:"adsDto.adsDatesList"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('ek-input-image',{ref:"adsImages",attrs:{"contain":"","disabled":_vm.isOrder,"quality":0.5,"required":true,"noDeleteBtn":"","label":"صورة","title":"انقر او انقل صورة للتحميل","currentImage":_vm.$store.getters['app/domainHost'] + '/' + _vm.adsDto.imageUrl,"name":"صور الإعلان"},on:{"input":_vm.updateAdsFiles}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ek-input-text',{attrs:{"disabled":_vm.isOrder,"rules":[{ type: 'required', message: 'عنوان الإعلان' }],"label":"عنوان الإعلان","placeholder":"ادخل عنوان الإعلان","name":"adTitle"},model:{value:(_vm.adsDto.serviceName),callback:function ($$v) {_vm.$set(_vm.adsDto, "serviceName", $$v)},expression:"adsDto.serviceName"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ek-input-textarea',{attrs:{"disabled":_vm.isOrder,"rules":[
            {
              type: 'required',
              message: 'النص مطلوب',
            } ],"label":"النص","placeholder":"ادخل النص","name":"description"},model:{value:(_vm.adsDto.content),callback:function ($$v) {_vm.$set(_vm.adsDto, "content", $$v)},expression:"adsDto.content"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }