<template>
  <b-card class="ads-details-card p-1">
    <b-card-header
      class="ads-details-card__header position-relative"
      header-class="p-0 mb-2"
    >
      <div class="ads-details-card__title-container d-flex">
        <unicon name="tv-retro" width="20" height="20" />
        <h4 class="ads-details-card__title ml-1 mb-0">تفاصيل الإعلان</h4>
      </div>
      <div class="ads-details-card__ads-status-container d-flex align-items-center">
        <div class="ads-details-card__ads-status-title d-flex">حالة الإعلان:</div>
        <StatusBadge
          class="ads-details-card__ads-status ml-1"
          :statusList="adsStatus"
          :selectedStatusNumber="adsDto.dashResponse"
        />
      </div>
    </b-card-header>

    <div class="card-title-seperator" />

    <b-form class="mt-1">
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <ek-input-select
                :disabled="isOrder"
                :options="activitiesList"
                :rules="[
                  {
                    type: 'required',
                    message: 'اسم الفعالية مطلوب',
                  },
                ]"
                v-model="adsDto.senderId"
                @input="updateSelctedActivity"
                label="اسم الفعالية"
                name="senderId"
              />
            </b-col>

            <b-col md="12">
              <ek-input-text
                disabled
                readonly
                label="النشاط التجاري"
                name="CommercialActivity"
                :value="adsDto.comarcialActivityName"
              />
            </b-col>
            <b-col md="12">
              <ek-input-text
                readonly
                disabled
                label="المدينة"
                name="city"
                :value="adsDto.cityName"
              />
            </b-col>
            <b-col md="12">
              <ek-date-picker
                :disabled="isOrder"
                range
                v-model="adsDto.adsDatesList"
                @input="updateAdsDate($event)"
                :rules="[
                  {
                    type: 'required',
                    message: 'تاريخ الإعلان مطلوب',
                  },
                  {
                    type: 'min:15',
                    message: 'يرجى إدخال تاريخ بداية و تاريخ نهاية',
                  },
                ]"
                label="تاريخ الإعلان"
                name="adsDate"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col md="6">
          <ek-input-image
            contain
            :disabled="isOrder"
            :quality="0.5"
            :required="true"
            noDeleteBtn
            label="صورة"
            title="انقر او انقل صورة للتحميل"
            :currentImage="$store.getters['app/domainHost'] + '/' + adsDto.imageUrl"
            name="صور الإعلان"
            ref="adsImages"
            @input="updateAdsFiles"
          >
          </ek-input-image>
        </b-col>

        <b-col md="12">
          <ek-input-text
            :disabled="isOrder"
            :rules="[{ type: 'required', message: 'عنوان الإعلان' }]"
            label="عنوان الإعلان"
            placeholder="ادخل عنوان الإعلان"
            v-model="adsDto.serviceName"
            name="adTitle"
          />
        </b-col>
        <b-col md="12">
          <ek-input-textarea
            :disabled="isOrder"
            :rules="[
              {
                type: 'required',
                message: 'النص مطلوب',
              },
            ]"
            label="النص"
            placeholder="ادخل النص"
            v-model="adsDto.content"
            name="description"
          />
        </b-col>
      </b-row>
    </b-form>

    <template #footer>
      <slot name="actions" v-bind="adsDto"> </slot>
    </template>
  </b-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import StatusBadge from "@global/components/StatusBadge";
export default {
  components: {
    StatusBadge,
  },
  computed: {
    ...mapState({
      adsDto: (state) => state.adsAndOffers.adsDto,
      adsStatus: (state) => state.adsAndOffers.adsStatus,
      activitiesList: ({ activities }) => activities.activities,
    }),
    isOrder() {
      return (
        this.$route.name == "orderAdsRequest" ||
        this.$route.name == "ActivitiyAdsOrderDetails"
      );
    },
  },
  methods: {
    ...mapMutations(["Update_Ads_Dto_Date", "Update_Ads_Dto_Activity_Details"]),
    updateAdsFiles({ file }) {
      console.log("event", file);
      this.adsDto.file = file;
      console.log(this.adsDto);
    },
    updateSelctedActivity($event) {
      let activity = this.activitiesList.find((item) => item.id == $event);

      this.Update_Ads_Dto_Activity_Details(activity);
    },
    updateAdsDate($event) {
      this.Update_Ads_Dto_Date($event);
    },
  },
};
</script>
