<template>
  <ValidationObserver ref="adsOrderDetailsObserver">
    <AdsForm>
      <template #actions>
        <div class="d-flex justify-content-between ads-order-details-card__footer mt-1">
          <div>
            <b-button
              variant="primary"
              @click="acceptCurrentAds"
              :disabled="adsDto.dashResponse == 2 || adsDto.dashResponse == 3"
              >قبول</b-button
            >
            <b-button
              variant="outline-danger"
              class="mx-2"
              @click="rejectCurrentAds"
              :disabled="adsDto.dashResponse == 2 || adsDto.dashResponse == 3"
              >رفض</b-button
            >
          </div>
          <div>
            <b-button variant="outline-danger" class="mx-2" @click="deleteAdsOrder">
              حذف
            </b-button>
            <b-button variant="outline-primary" @click="navigateToOrders">تراجع</b-button>
          </div>
        </div>
      </template>
    </AdsForm>
  </ValidationObserver>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import AdsForm from "../../adsAndOffers/components/AdsForm.vue";

export default {
  components: {
    AdsForm,
  },
  props: {
    id: String,
    activityId: String,
  },
  computed: {
    ...mapState({
      adsDto: (state) => state.adsAndOffers.adsDto,
      orderStatusList: (state) => state.orders.orderStatusList,
      activitiesList: ({ activities }) => activities.activities,
    }),
    ...mapGetters(["adsStatusName", "adsIsActive", "adsIsExpired"]),
    isActivityOrder() {
      return this.$route.name == "ActivitiyAdsOrderDetails";
    },
  },
  created() {
    this.fetchAdsOrderById(this.id);
  },
  beforeDestroy() {
    this.Reset_Ads_Dto();
  },
  data: () => ({
    config: {
      allowInput: true,
      mode: "range",
      min: "",
      max: "",
    },
  }),
  methods: {
    ...mapActions([
      "fetchAdsOrderById",
      "acceptAdsOrder",
      "rejectAdsOrder",
      "acceptActivityAdsOrder",
      "rejectActivityAdsOrder",
      "deleteRangeAdsOrder",
    ]),
    ...mapMutations([
      "Reset_Ads_Dto",
      "Update_Ads_Image",
      "Update_Ads_Dto_Date",
      "Update_Ads_Dto_Activity_Details",
    ]),
    acceptCurrentAds() {
      if (!this.isActivityOrder) {
        this.acceptAdsOrder(this.id);
      } else {
        this.acceptActivityAdsOrder({
          id: this.id,
          activityId: this.activityId,
        });
      }
    },
    rejectCurrentAds() {
      if (!this.isActivityOrder) {
        this.rejectAdsOrder(this.id);
        return;
      }

      this.rejectActivityAdsOrder({
        id: this.id,
        activityId: this.activityId,
      });
    },
    navigateToOrders() {
      if (this.isActivityOrder) {
        this.$router.push(`/admin/activities/${this.activityId}`);
        return;
      }
      this.$router.push("/admin/orders");
    },
    updateSelctedActivity($event) {
      let activity = this.activitiesList.find((item) => item.id == $event);

      this.Update_Ads_Dto_Activity_Details(activity);
    },
    updateAdsDate($event) {
      this.Update_Ads_Dto_Date($event);
    },
    updateAdsFiles($event) {
      console.log("event", $event);
      this.adsDto.file = $event;
      console.log(this.adsDto);
    },
    beforeDestroy() {
      this.$refs.adsImages.reset();
    },
    deleteCurrentAds() {
      this.deleteAds(this.id);
    },
    selectedActivity() {
      return this.activitiesList.find((el) => el.id == this.adsDto.senderId);
    },
    deleteAdsOrder() {
      this.deleteRangeAdsOrder([{ id: this.id, requestTypes: 1 }]);
    },
  },
};
</script>

<style scoped lang="scss">
.ads-order-details-card {
  &__header {
    padding: 0rem !important;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
    }
  }

  &__title {
    font-size: 1.4rem;

    color: #5e5873;
  }

  &__ads-status-title {
    font-size: 1.1rem;

    color: #5e5873;
  }

  &__ads-status {
    background-color: rgba(0, 127, 255, 0.12) !important;
    font-size: 1rem;
    border-radius: 200px;
    font-weight: 700;
    padding: 0.4rem;
    width: 60px;
    &--isActive {
      color: #28c76f !important;
    }

    &--isExpired {
      color: #ff9f43 !important;
    }
  }

  &__footer-button {
    padding: 10px 25px;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
  }

  &__update-button {
    color: #ffffff !important;
  }

  &__remove-button {
    background-color: white !important;
    color: #ff0000 !important;
    border-color: #ff0000 !important;
  }

  &__back-button {
    color: #007fff !important;
    border-color: #007fff !important;
  }
  &__delete-button {
    background-color: #edf4f9 !important;
    color: #000000 !important;
  }
}
</style>
