<template>
  <div>
    <b-badge pill class="status-badge" variant="light" :class="`text-${statusColor}`">{{
      statusList.find((item) => item.number == this.selectedStatusNumber)
        ? statusList.find((item) => item.number == this.selectedStatusNumber).name
        : ""
    }}</b-badge>
  </div>
</template>

<script>
export default {
  props: {
    statusList: Array,
    selectedStatusNumber: Number,
  },
  computed: {
    statusColor() {
      let statusName = "";

      if (!this.statusList.find((item) => item.number == this.selectedStatusNumber)) {
        return "";
      }

      switch (
        this.statusList.find((item) => item.number == this.selectedStatusNumber).color
      ) {
        case "primary":
          statusName = "primary";
          break;
        case "warning":
          statusName = "warning";
          break;
        case "success":
          statusName = "success";
          break;
        case "danger":
          statusName = "danger";
          break;
      }
      return statusName;
    },
  },
};
</script>

<style scoped lang="scss">
.status-badge {
  padding: 0.7rem;

  background-color: rgba(0, 127, 255, 0.12);
}
</style>
